const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;
const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * MINUTES_IN_HOUR;
const SECONDS_IN_DAY = SECONDS_IN_HOUR * HOURS_IN_DAY;

export const getDurationInSeconds = ({
  days = 0,
  hours = 0,
  minutes = 0,
}): number => days * SECONDS_IN_DAY + hours * SECONDS_IN_HOUR + minutes * SECONDS_IN_MINUTE;

export const getDurationInMilliseconds = (params: Parameters<typeof getDurationInSeconds>[0]): number => getDurationInSeconds(params) * MILLISECONDS_IN_SECOND;
